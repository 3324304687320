import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import windData from "./markers.json"
import windmillData from '../../windmill.json'
import icon from '../../Images/2776000.png'
import posIcon from '../../Images/blue_person.png'
import otherIcon from "../../Images/marker.svg"


import "leaflet/dist/leaflet.css";
import markerData from "./markers.json"
import { NavLink } from "react-router-dom";
import { MarkerCluster } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";

const createClusterCustomIcon = function (cluster: MarkerCluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "custom-marker-cluster",
    iconSize: L.point(33, 33, false)
  });
};

let markers = []
let isLoaded = false
  

function Map() {
  let change = 0
  let storage1 = JSON.parse(window.localStorage.getItem('faves'))
  const [inputs, setInputs] = useState({
    message: " ",
    faves: storage1
  })
  if (window.localStorage.getItem('faves') === null) {
    window.localStorage.setItem("faves", JSON.stringify([]))
    //console.log("created");
  }else{
    //console.log(JSON.parse(window.localStorage.getItem('faves')));
  }
  const [id, setId] = useState("all")
  setTimeout(() => {
    isLoaded = true
  }, 1000);
  const customMarker = new L.icon({
    iconUrl: posIcon,
    iconSize: [33, 33],
    iconAnchor: [16, 16]
  });
  const customWindmill = new L.icon({
    iconUrl: icon,
    iconSize: [25, 30],
    iconAnchor: [13, 0]
  });
  function LocationMarker() {
    const [position, setPosition] = useState(null);
    const [bbox, setBbox] = useState([]);

    const map = useMap();

    useEffect(() => {
      map.locate().on("locationfound", function (e) {
        setPosition(e.latlng);
        map.flyTo(e.latlng, map.getZoom());
        const radius = e.accuracy;
        const circle = L.circle(e.latlng, radius);
        circle.addTo(map);
        setBbox(e.bounds.toBBoxString().split(","));
      });
    }, [map]);
    
    return position === null ? null : (
      <Marker position={position} icon={customMarker}>
        
        <Popup>
          Du er her
        </Popup>
      </Marker>
    );
  }
function radioChange(event) {
  ////console.log(event.target.id);
  setId(event.target.id)
  document.getElementById('small').style.backgroundColor = "white"
  document.getElementById('medium').style.backgroundColor = "white"
  document.getElementById('big').style.backgroundColor = "white"
  document.getElementById('all').style.backgroundColor = "white"
  event.target.style.backgroundColor = "gray"
}

function faveri(event) {
  //console.log(inputs.faves);
  
  if (!storage1) {
    
  }else{
    if (event.target.checked === true) {
      alert('Mølle tilføjet til favoritter')
      let mySto = JSON.parse(window.localStorage.getItem('faves'))
      let storage = inputs.faves
      storage?.push(parseInt(event.target.id))
      
      setInputs({ ...inputs, faves: storage })
      window.localStorage.setItem('faves', JSON.stringify(inputs.faves))
      ////console.log(mySto);
      
    }else{
      alert('Mølle fjernet fra favoritter')
      let mySto = JSON.parse(window.localStorage.getItem('faves'))
      let storage = inputs.faves
      storage?.splice(storage.indexOf(parseInt(event.target.id)), 1)
      
      setInputs({ ...inputs, faves: storage })
      window.localStorage.setItem('faves', JSON.stringify(inputs.faves))
      ////console.log(mySto);
    }
    
  }
  //PArsing problems with local storage
  // for (let index = 0; index < window.localStorage.getItem('faves').length; index++) {
  //   const element = window.localStorage.getItem('faves')[index];
  //   ////console.log(element);
    
  // }
}
  return (
    <>

    
    <MapContainer
      center={[56.1827476,9.4256881]}
      zoom={13}
      scrollWheelZoom
      style={{ height: "90vh" }}
    >

      <TileLayer
        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {change === 0 ? <LocationMarker /> : null}
      
      <MarkerClusterGroup chunkedLoading>
      {windmillData.map((marker,index) => {
         let pos = [
          {"position": {
            "lat": marker.Latitude,
            "lng": marker.Longtitude
          }
         }
        ]
        let sweapArea = parseFloat(marker.Rotor_diameter) / 2
        sweapArea = sweapArea * sweapArea * 3.14
        return (
          <Marker position={pos[0].position} icon={customWindmill}>
          <Popup>
            <ul key={index}>
        <li><strong> Møllenummer: {marker.Møllenummer}</strong></li>
        <li><strong> Ejerlav: {marker.Ejerlav}</strong></li>
        <li><strong> Nettilslutning: {marker.Nettilslutning}</strong></li>
        <li><strong>Fabrikat: {marker.Fabrikat}</strong></li>
        <li><strong>Model: {marker.Model}</strong></li>
        <li><strong>Rotor diameter: {marker.Rotor_diameter} m</strong></li>
        <li><strong>Navhøjde: {marker.Navhøjde} m</strong></li>
        <li><strong>Rotorareal: {sweapArea.toFixed(2)} m2</strong></li>
                    <li><strong>Turbine total højde: {parseFloat(marker.Navhøjde) + parseFloat(marker.Rotor_diameter)} m</strong></li>
        <li><strong>Kommune: {marker.Kommune}</strong></li>
        <li><strong>Type af placering: {marker.Placering}</strong></li>
        
        <li><NavLink to={`/${index}`}>Se udvidet info om møllen</NavLink></li>
        <h3>Gem mølle <input type="checkbox" name="fave" checked={ !inputs?.faves?.includes(index) ? false : true} onChange={faveri} id={index} /></h3>
        <p>Gemte møller vises i navigations menuen efter næste genindlæsning af siden</p>
        </ul>
          </Popup>
        </Marker>)

          })}
        </MarkerClusterGroup>
        
        
      
    </MapContainer>
    </>
  );
}

export default Map;
