import React from 'react'
import { useParams } from 'react-router-dom'
import { HistoricalStyle } from './Historical.style'
import models from "../../models.json"
import deadWindmills from '../../deadWindmills.json'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ArcElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';

import faker from 'faker';

function Historical() {
    
const { id } = useParams()
let proChart = []
let proRevenue = []
let labels = []
let formatted = deadWindmills[id].Afmeldning.split('-')
let formatted2 = deadWindmills[id].Nettilslutning.split('-')
let formatDates = formatted[2] - formatted2[2]
let production = 0
for (let index = 1977; index < 2022; index++) {
    const element = deadWindmills[id][`${index}`];
    if (element) {
     production = production + element 
     proChart.push(element)
     proRevenue.push(element * 0.35)
     labels.push(index)
    }
    
    
}
let sweapArea = parseFloat(deadWindmills[id].Rotor_diameter) / 2
sweapArea = sweapArea * sweapArea * 3.14
//console.log(production);

let tarif = production * 0.1 // 1 øre pr kWh
let investment = deadWindmills[id].Kapacitet_i_kW * 7500 // Korrekt
let building = 25 * investment / 100
let maintain = investment * 2.5 / 100 //% af vindmøllens pris til drift & vedligehold //Korrekt
//console.log("opsætning", building);
let costs = formatDates > 20 ? tarif + maintain + building + 20 * investment / 100  : tarif + maintain + building  // hvis møllen er mere end 20 år gammel, er der stor chance for at noget skulle udskiftes og her går vi udfra at delen kostede 20% af investeringen
let benefits1 = deadWindmills[id].Kapacitet_i_kW / 100000 * 6600
//console.log("kW", benefits1);
let benefits2 = benefits1 + sweapArea
//console.log("sweap", sweapArea);
let benefits2_1 = benefits2 * 5.6
//console.log("Tilskud pr fuldlasttime i kr", benefits2_1);
let benefits3 = benefits2_1 * 0.13 //pris pr kWh
//console.log("Tilskud ialt", benefits3);
let profit = production * 0.35
let revenue = investment + profit + benefits3

let costsPrYear = costs / formatDates
let realRente = 5 * investment / 100
realRente = realRente * formatDates

let realRevenue = revenue - costs - realRente

let obj = models.find(o => o.Model === deadWindmills[id].Model);
//console.log(obj);
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: 'Produktion gennem årene',
    },
  },
};

const options2 = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: 'Udgifter og indtægter',
    },
  },
};

const data = {
  labels,
  proChart,
  proRevenue,
  datasets: [
    {
      fill: false,
      label: 'kWh',
      data: proChart,
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
    {
      fill: false,
      label: 'DKK',
      data: proRevenue,
      borderColor: 'rgb(220, 53, 235)',
      backgroundColor: 'rgba(220, 53, 235, 0.5)',
    },
  ],
};

ChartJS.register(ArcElement, Tooltip, Legend);

const pieData = {
  labels: ['Strøm indtægter', 'Investering', 'Støtte', 'Tarif', 'Vedligeholdelse', 'Opsætning', 'Real rente'],
  datasets: [
    {
      label: 'DKK',
      data: [profit, investment, 0, tarif, maintain, building, realRente],
      backgroundColor: [
        'rgb(18, 141, 7)',
        'rgb(18, 141, 7)',
        'rgb(18, 141, 7)',
        'rgb(255, 0, 55)',
        'rgb(255, 0, 55)',
        'rgb(255, 0, 55)',
        'rgb(255, 0, 55)',
        
      ],
      borderColor: [
        '#000000',
      ],
      borderWidth: 1,
    },
  ],
};
  return (
    <HistoricalStyle>
        <div id='sidebarDiv'>
            <h3>{deadWindmills[id].Møllenummer}</h3>
            <h4>Placering: {deadWindmills[id].Ejerlav} i {deadWindmills[id].Kommune} kommune</h4>
            {/* <div>
                <button>I alt</button>
                <button>Dato til dato</button>
            </div> */}
            <h3>Leveår: {formatDates}</h3>
            
            
            
            {/* <h3>Affald generert: {50} kg</h3> */}
    
            <div id='pieDiv'>
              <Pie data={pieData} options={options2}/>
            </div>
            <ul>
      <li><strong> Nettilslutning: {deadWindmills[id].Nettilslutning}</strong></li>
      <li><strong> Afmeldt: {deadWindmills[id].Afmeldning}</strong></li>
      <li><strong>Fabrikat: {deadWindmills[id].Fabrikat}</strong></li>
      <li><strong>Model: {deadWindmills[id].Model}</strong></li>
      <li><strong>Antal stadig i drift: {obj?.Antal_i_DK ? obj.Antal_i_DK : 0} </strong></li>
      <li><strong>Rotor diameter: {deadWindmills[id].Rotor_diameter} m</strong></li>
      <li><strong>Navhøjde: {deadWindmills[id].Navhøjde} m</strong></li>
      <li><strong>Kapacitet: {deadWindmills[id].Kapacitet_i_kW} kW</strong></li>
      <li><strong>Rotorareal: {sweapArea.toFixed(2)} m2</strong></li>
      <li><strong>Turbine total højde: {parseFloat(deadWindmills[id].Navhøjde) + parseFloat(deadWindmills[id].Rotor_diameter)} m</strong></li>
      </ul>
            <h3 className='bottom'>Møllen gav {realRevenue.toLocaleString('etc')} DKK i {realRevenue < 0 ? "estimeret underskud" : "estimeret overskud"}</h3>
        </div>
        <div>

        
        <div id='cardArea'>
          <div className='cardOne'>
            <h3>Indtægter: {revenue.toLocaleString('etc')} DKK</h3>
          </div>
          <div className='cardTwo'>
          <h3>Udgifter: {costs.toLocaleString('etc')} DKK</h3>
          </div>
          <div className='cardThree'>
          <h3 >Udgifter om året: {costsPrYear.toLocaleString('etc')} DKK</h3>
          </div>
          <div className='cardFour'>
          <h3 >Produktion i alt: {production.toLocaleString('etc')} kWh</h3>
          </div>
                
        </div> 
        <div id='lineDiv'>
<Line options={options} data={data} />
        </div> 
        </div>

    </HistoricalStyle>
  )
}

export default Historical