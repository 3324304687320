import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { GraphStyle } from './Graph.style';

export function Graph(probs) {
  let kWh = probs.kWh
  let kr = probs.kr
  let windspeed = probs.windspeed
  let labels = probs.labels
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
   const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Produktion pr time',
      },
    },
  };
  const data = {
    kWh,
    kr,
    windspeed,
    labels,
    datasets: [
      {
        label: 'Produktion i kWh',
        data: kWh,
        borderColor: 'rgb(40, 202, 7)',
        backgroundColor: 'rgba(53, 162, 235, 0.4)',
      },
      {
        label: 'Indtjening i DKK',
        data: kr,
        borderColor: 'rgb(53, 65, 235)',
        backgroundColor: 'rgba(53, 56, 235, 0.5)',
      },
      {
        label: 'Vindhastighed i m/s',
        data: windspeed,
        borderColor: 'rgb(7, 156, 202)',
        backgroundColor: 'rgba(53, 162, 235, 0.4)',
      },
    ],
  };

  return (
    <> 
     <div>
  
    <GraphStyle>
  <Line options={options} data={data} />
  </GraphStyle>
 
  


  </div>
  
  </>
  )
  
}
