import styled from "styled-components";

export const LocationStyle = styled.article`
 pointer-events: none;
    width: 100%;
    .gmap_canvas{

        height: fit-content;
        margin-left: 2vw;
        padding: 0;
        margin: 0;
        margin-left: 2vw;   

        iframe{
            width: 95%;
                   border-radius: 20px; 
        }
    }
` 