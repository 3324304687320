import React, { useEffect, useState } from 'react'
import models from "../../models.json"
import windmill from "../../windmill.json"
import Production from '../../Components/Production/Production'
import { useParams } from 'react-router-dom'
import Location from '../../Components/Location/Location'
import Weather from '../../Components/Weather/Weather'
import { Graph } from '../../Components/Production/Graph'
import TheWindmill from '../../Components/TheWindmill/TheWindmill'
import Windy from '../../Components/Production/Windy'
import { DashboardStyle } from './Dashboard.style'
import NotFound from '../NotFound/NotFound'
import axios from 'axios';
import CompareData from '../../energyConsumption.json'
import Compare1 from '../../Components/ComparePower/Compare1'
import Compare2 from '../../Components/ComparePower/Compare2'

let windspeed = []
let labels = []
let kWh = []
let kr = []
let spotPris = []
let allPower = 0
let price = []
let allPrice = 0
let weatherData = []
let priceData = []

let currentHour = new Date().getHours()
let hours = currentHour + 1
const currentDate = new Date();
const day = currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate();
const month = (currentDate.getMonth() + 1) < 10 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);

const formattedDate = `${day}/${month}/${currentDate.getFullYear()}`;
//console.log(formattedDate);

function windmillProduction(windSpeed, rotorDiameter) {
  const airDensity = 1.225; // kg/m^3
  const efficiency = 0.40; // 35% efficiency
  const sweptArea = Math.PI * (rotorDiameter / 2) ** 2; // m^2
  const power = 0.5 * airDensity * sweptArea * (windSpeed ** 3) * efficiency; // W
  const energy = power / 1000; // kWh
  return parseFloat(energy.toFixed(0)); // return result with 2 decimal places
}
function sweptArea(radius) {
  const bladeArea = Math.PI * radius ** 2; // Calculate the area of one blade
  const totalArea = bladeArea * 1; // Multiply by the number of blades
  return totalArea;
}
function windmillProduction2(windSpeed, rotorDiameter) {
  let Jouels = 0
  let result = 0
  let windspeed = windSpeed
  windspeed = windspeed*windspeed*windspeed
   let energy = 0.5*sweptArea(rotorDiameter/2)*1.225*windspeed*0.40*3600
   Jouels = 0.4 * energy
   
   result = 0.0000002778 * Jouels
  
  
   return parseInt(result)
}

function Dashboard(probs) {


  const { id } = useParams();
  const [weather, setWeather] = useState([])
  const [isChecked, setIsChecked] = useState(window.localStorage.getItem('graphValue') == 24 ? true : false);
//console.log(window.localStorage.getItem('graphValue'));
let obj = models.find(o => o.Model === windmill[id].Model);


  useEffect(() => {        
    const getPrice = async () => {
      const response = await axios.get(`https://www.elprisenligenu.dk/api/v1/prices/${currentDate.getFullYear()}/${month}-${day}_${windmill[id].Longtitude < 10.95 ? "DK2" : "DK1"}.json`)
      //console.log("Dk?",windmill[id].Longtitude > 10.95 ? "DK2" : "DK1");
      //console.log(windmill[id].Longtitude);
      //console.log("Status", response.data)
      price = response.data
     
  } 
    const getWeather = async () => {
      const response = await axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${windmill[id].Latitude}&lon=${windmill[id].Longtitude}&appid=1bfe429badd53bc6c7843d9d3f8f59eb&lang=da&units=metric`)
      ////console.log("Status", response.status);
      setWeather([])
      weatherData = response.data
     
  } 
  const getOpenWeather = async () => {
      const res = await axios.get(`https://api.open-meteo.com/v1/forecast?latitude=${windmill[id].Latitude}&longitude=${windmill[id].Longtitude}&hourly=pressure_msl,windspeed_80m,winddirection_80m,temperature_80m,temperature_1000hPa&windspeed_unit=ms&timeformat=unixtime&timezone=Europe%2FBerlin&past_days=0`)
      ////console.log("status", res.status);
       setWeather([...weather, res.data, weatherData]);   
       windspeed = []
       labels = []
       kWh = []
       kr = []
       spotPris = []
       allPower = 0
       allPrice = 0
       hours = isChecked == false ? currentHour + 1 : 23
       for (let index = 0; index < 24; index++) {
        const wind = res.data.hourly.windspeed_80m[index]
        let power = wind < 4 ? 0 : parseFloat(windmillProduction(wind, windmill[id].Rotor_diameter)*2)
        power = parseFloat(windmill[id].Kapacitet_i_kW) > power ? power : parseFloat(windmill[id].Kapacitet_i_kW)
        ////console.log(price[index]?.DKK_per_kWh);
        let money = power * (price[index]?.DKK_per_kWh ? price[index]?.DKK_per_kWh : price[index]?.DKK_per_kWh === 0 ? 0 : 0.35)
        money = money < 0 ? 0 : money 
        windspeed.push(wind)
        kWh.push(power.toFixed(2))
        
        allPower = allPower + power
      allPrice = allPrice + price[index]?.DKK_per_kWh
        kr.push(money.toFixed(2))
        spotPris.push(price[index]?.DKK_per_kWh)
        if (isChecked === false) {
          if (index < currentHour + 1) {
            labels.push(index)
          }
        }else{
          labels.push(index)
        }
        
        }
    } 
getPrice()
if (price !== undefined) {
      getWeather() 
     
     setTimeout(() => {
    
     getOpenWeather()
     }, 200);
}
     
      
     
      


     //console.log(price[11]);
},[id]); 
 //console.log("hour", allPrice / new Date().getHours());
 let val = !weather.length == 0 ? parseInt(weather[weather.length - 2].hourly.winddirection_80m[currentHour]) : 0
 let deg;
 {(() => {
     if (val < 39 && val > 0) {
         deg = "N"; 
      } else if (val < 89 && val > 44) {
          deg = "NE";
      } else if (val < 134 && val > 90) {
          deg = "E";
      }
      else if (val < 179 && val > 135) {
          deg = "SE";
      }
      else if (val < 224 && val > 180) {
          deg = "S";
      }
      else if (val < 269 && val > 225) {
          deg = "SW";
      }
      else if (val < 269 && val > 225) {
          deg = "W";
      }
      else if (val < 314 && val > 270) {
          deg = "NW";
      }
      else if (val < 360 && val > 315) {
         deg = "NW";
      }
   })()}
   const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
//console.log(event.target.checked);
    if (event.target.checked === false) {
      window.localStorage.setItem('graphValue', 0)
      labels = []
      for (let index = 0; index < currentHour + 1; index++) {
        labels.push(index)
        
      }
    }else{
      window.localStorage.setItem('graphValue', 24)
      labels = []
      for (let index = 0; index < 24; index++) {
        labels.push(index)
        
      }
    }
    //window.location.reload()
  
    
  };
//console.log("all",allPrice);
       return (
    <>
    
    
    {parseInt(id) == id ?
    <DashboardStyle> 
            <div className='checkboxDiv'>
        <p>Se hele dagen</p>
      <label class="switch">
      <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
  <span class="slider round"></span>
</label>
<Production 
      weather={weather}
      thepower={allPower ? kWh : [0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,

      ]}
      price={allPrice ? spotPris : [0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
        0.35,
      ]}
      update={isChecked}
      />
</div>
      
      <Location 
      lat={windmill[id].Latitude}
      long={windmill[id].Longtitude}
      ejerlav={windmill[id].Ejerlav}
      />

      <Graph 
      kWh={kWh}
      kr={kr}
      windspeed={windspeed}
      labels={labels}
      />
      
      
      
      
      <Weather 
      weatherlength={weather.length}
      windspeed={!weather.length == 0 ? weather[weather.length - 2].hourly.windspeed_80m[currentHour] : 1}
      deg={weatherData ? deg : "?"}
      humidity={!weather.length == 0 ? weather[weather.length - 1].main.humidity : "ca 60"}
      forcast={!weather.length == 0 ? weather[weather.length - 1].weather[0].main : "?"}
      forcastDesc={!weather.length == 0 ? weather[weather.length - 1].weather[0].description : "?"}
      forcastImg={!weather.length == 0 ? "https://openweathermap.org/img/wn/" + weather[weather.length - 1].weather[0].icon + ".png" : "https://openweathermap.org/img/wn/" + "01n" + ".png"}
      pressure={!weather.length == 0 ? weather[weather.length - 2].hourly.pressure_msl[currentHour] : 0}
      temp={!weather.length == 0 ? weather[weather.length - 2].hourly.temperature_80m[currentHour] : "?"}
      />
      <Windy  
      lat={windmill[id].Latitude}
      long={windmill[id].Longtitude}
      />
      <TheWindmill   
      windData={windmill[id]}
      windspeed={windspeed}
      rotorRadius={windmill[id].Rotor_diameter/2}
      modelObj={obj}

      />

      {/* <Compare1 
      power={allPower}
      oneHandling={CompareData[0].Handling}
      onekWh_i_timen={CompareData[0].kWh_i_timen}

      twoHandling={CompareData[1].Handling}
      twokWh_i_timen={CompareData[1].kWh_i_timen}

      threeHandling={CompareData[2].Handling}
      threekWh_i_timen={CompareData[2].kWh_i_timen}

      fourHandling={CompareData[3].Handling}
      fourkWh_i_timen={CompareData[3].kWh_i_timen}
      />
            <Compare2 
            power={allPower}
            oneHandling={CompareData[4].Handling}
            onekWh_i_timen={CompareData[4].kWh_i_timen}
      
            twoHandling={CompareData[5].Handling}
            twokWh_i_timen={CompareData[5].kWh_i_timen}
      
            threeHandling={CompareData[6].Handling}
            threekWh_i_timen={CompareData[6].kWh_i_timen}
      
            fourHandling={CompareData[7].Handling}
            fourkWh_i_timen={CompareData[7].kWh_i_timen}
      /> */}
      </DashboardStyle> : <NotFound />}
    
    
    </>
  )


}

export default Dashboard
