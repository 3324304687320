import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
* {
  box-sizing: auto;
}

#page-wrap {
  padding-bottom: 10px;
  padding-top: 10px;
}
body{
  margin: 0;
  padding: 0;
}
html{
  margin: 0;
  padding: 0;
  background-color: #1A74CA;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  h6{
    margin-top: 0;
    padding-top: 0;
  }
  h4{
    margin-bottom: 0;
  }
}
.MainGrid{
  
  display: grid;
  grid-template-columns: 20% 80%;
  height: max-content;
  background-color: #1A74CA;
  
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  body{
margin-right: 0;
padding-right: 0;
margin-left: 0;
    padding-left: 0;
    h6,h4{
      
      text-align: center;
    }
}
  .MainGrid{
    
    display: grid;
    grid-template-columns: 1fr;
    height: max-content;
    width: 100%;
    margin-left: 0;
    padding-left: 0;
  }
}
`;