import React from 'react'
import { HeaderStyle } from './Header.style'
function Header() {
  return (
    
      <HeaderStyle>
        <div>
         <img src={require("../../Images/vindmølleprojektet.png")} alt="Logo" id='logo' />   
        </div>
        </HeaderStyle>
    
  )
}

export default Header