import React from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import Calculations from '../Pages/Calculations/Calculations'
import Dashboard from '../Pages/Dashboard/Dashboard'
import Dead from '../Pages/Dead/Dead'
import Disclaimer from '../Pages/Disclaimer/Disclaimer'
import Historical from '../Pages/Historical/Historical'
import NearMe from '../Pages/NearMe/NearMe'
import NotFound from '../Pages/NotFound/NotFound'
import Simulation from '../Pages/Simulation/Simulation'
import windmill from "../windmill.json"

function AppRouter() {
  const { id } = useParams();
  return (
    <>
    
    <Routes>
        <Route index element={<NearMe />}></Route> 
        <Route path="/near" element={<NearMe />}></Route>
        <Route path="/disclaimer" element={<Disclaimer />}></Route>
        <Route path="/calculations" element={<Calculations />}></Route>
        <Route path="/dead" element={<Dead />}></Route>
        {windmill.map(function(item, index){
          return (
            <Route key={index} path={`/dead/:id`} element={<Historical />}></Route>
          )})}
        
        {windmill.map(function(item, index){
          return (
            <Route key={index} path={`/:id`} element={<Dashboard  />}></Route>
          )})}
          <Route path='*' element={<Calculations />}></Route>
    </Routes>
    </>
  )
}

export default AppRouter