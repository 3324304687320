import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import windData from "./markers.json"
import windmillData from '../../deadWindmills.json'
import icon from '../../Images/2776000.png'
import posIcon from '../../Images/blue_person.png'
import otherIcon from "../../Images/marker.svg"


import "leaflet/dist/leaflet.css";
import markerData from "./markers.json"
import { NavLink } from "react-router-dom";
import { MarkerCluster } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";

const createClusterCustomIcon = function (cluster: MarkerCluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "custom-marker-cluster",
    iconSize: L.point(33, 33, false)
  });
};

let markers = []
let isLoaded = false
windmillData.forEach(element => {
   
   if (element.Latitude && element.Longtitude) {
    let sweapArea = parseFloat(element.Rotor_diameter) / 2
    sweapArea = sweapArea * sweapArea * 3.14
    
         markers.push({
          Index: windmillData.indexOf(element),
          RotorAreal: sweapArea,
            Møllenummer: element.Møllenummer,
            Nettilslutning: element.Nettilslutning,
            Ejerlav: element.Ejerlav,
            Fabrikat: element.Fabrikat,
            Model: element.Model,
            Rotor_diameter: element.Rotor_diameter,
            Navhøjde: element.Navhøjde,
            Total_height: parseFloat(element.Navhøjde) + parseFloat(element.Rotor_diameter),
            Placering: element.Placering,
            Kommune: element.Kommune,
            position: {
      lat: element.Latitude,
      lng: element.Longtitude
   }})
   }

});

function Map() {
  const [id, setId] = useState("all")
  setTimeout(() => {
    isLoaded = true
  }, 1000);
  const customMarker = new L.icon({
    iconUrl: posIcon,
    iconSize: [33, 33],
    iconAnchor: [16, 16]
  });
  const customWindmill = new L.icon({
    iconUrl: icon,
    iconSize: [25, 30],
    iconAnchor: [13, 0]
  });
  function LocationMarker() {
    const [position, setPosition] = useState(null);
    const [bbox, setBbox] = useState([]);

    const map = useMap();

    useEffect(() => {
      map.locate().on("locationfound", function (e) {
        setPosition(e.latlng);
        map.flyTo(e.latlng, map.getZoom());
        const radius = e.accuracy;
        const circle = L.circle(e.latlng, radius);
        circle.addTo(map);
        setBbox(e.bounds.toBBoxString().split(","));
      });
    }, [map]);
    
    return position === null ? null : (
      <Marker position={position} icon={customMarker}>
        
        <Popup>
          Du er her
        </Popup>
      </Marker>
    );
  }
function radioChange(event) {
  ////console.log(event.target.id);
  setId(event.target.id)
}
  return (
    <MapContainer
      center={[56.1827476,9.4256881]}
      zoom={13}
      scrollWheelZoom
      style={{ height: "90vh" }}
    >

      <TileLayer
        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LocationMarker />
      
      <MarkerClusterGroup chunkedLoading>
      {windmillData.map((marker,index) => {
         let pos = [
          {"position": {
            "lat": marker.Latitude,
            "lng": marker.Longtitude
          }
         }
        ]
        let sweapArea = parseFloat(marker.Rotor_diameter) / 2
        sweapArea = sweapArea * sweapArea * 3.14
      return (
        <Marker position={pos[0].position} icon={customWindmill}>
        <Popup>
          <ul key={index}>
      <li><strong> Møllenummer: {marker.Møllenummer}</strong></li>
      <li><strong> Ejerlav: {marker.Ejerlav}</strong></li>
      <li><strong> Nettilslutning: {marker.Nettilslutning}</strong></li>
      <li><strong> Afmeldt: {marker.Afmeldning}</strong></li>
      <li><strong>Fabrikat: {marker.Fabrikat}</strong></li>
      <li><strong>Model: {marker.Model}</strong></li>
      <li><strong>Rotor diameter: {marker.Rotor_diameter} m</strong></li>
      <li><strong>Navhøjde: {marker.Navhøjde} m</strong></li>
      <li><strong>Navhøjde: {marker.Kapacitet_i_kW} kW</strong></li>
      <li><strong>Rotorareal: {sweapArea.toFixed(2)} m2</strong></li>
      <li><strong>Turbine total højde: {parseFloat(marker.Navhøjde) + parseFloat(marker.Rotor_diameter)} m</strong></li>
      
      <li><strong>Kommune: {marker.Kommune}</strong></li>
      <li><strong>Type af placering: {marker.Placering}</strong></li>
      
      <li><NavLink to={`/dead/${index}`}>Se historiske data om møllen</NavLink></li>
      </ul>
        </Popup>
      </Marker>)

          })}
        </MarkerClusterGroup>
        
        
      
    </MapContainer>
  );
}

export default Map;
