import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import windmill from "../../windmill.json"
import { useParams } from 'react-router-dom';
import { SidebarStyle } from '../Sidebar/Sidebar.style';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import Header from '../Header/Header';
import questionImg from '../../Images/question-mark-sign-icon.svg'
import calcImg from '../../Images/calculate-math-icon.svg'
import globeImg from '../../Images/globe-icon.svg'
import xImg from '../../Images/close-round-icon.svg'

function MenuTo() {
  const navigate = useNavigate();
  let storage1 = JSON.parse(window.localStorage.getItem('faves'))

  const { id } = useParams();
  const [inputs, setInputs] = useState({
    message: " ",
    faves: storage1,
    theId: id
  })
  if (window.localStorage.getItem('faves') === null) {
    window.localStorage.setItem("faves", JSON.stringify([]))
    //console.log("created");
  }else{
   // //console.log(JSON.parse(window.localStorage.getItem('faves')));
  }
      function handleChange (event) {
        let obs = []
        let str = event.target.value
        for (let index = 0; index < windmill.length; index++) {
          const element = windmill[index];
          if (Object.values(element).includes(str)) {
            obs.push(element)
          }
        }
        let str2 = obs
        //console.log(str2);
setInputs({ ...inputs, message: str2 })

  }
function faveri(event) {
  //console.log(inputs.faves);
  
  if (!storage1) {
    
  }else{
    if (event.target.checked === true) {
      let mySto = JSON.parse(window.localStorage.getItem('faves'))
      let storage = inputs.faves
      storage?.push(parseInt(event.target.id))
      
      setInputs({ ...inputs, faves: storage })
      window.localStorage.setItem('faves', JSON.stringify(inputs.faves))
      ////console.log(mySto);
      
    }else{
      let mySto = JSON.parse(window.localStorage.getItem('faves'))
      let storage = inputs.faves
      storage?.splice(storage.indexOf(parseInt(event.target.id)), 1)
      
      setInputs({ ...inputs, faves: storage })
      window.localStorage.setItem('faves', JSON.stringify(inputs.faves))
      ////console.log(mySto);
    }
    
  }
  //PArsing problems with local storage
  // for (let index = 0; index < window.localStorage.getItem('faves').length; index++) {
  //   const element = window.localStorage.getItem('faves')[index];
  //   ////console.log(element);
    
  // }

}

let count = 0
  return (
    
      <SidebarStyle>
        <nav>
            <ul>
              
              <li><span className='icon'><img src={questionImg} alt="question mark" /> </span><NavLink to="/disclaimer">Om projektet</NavLink></li>
              <li><span className='icon'><img src={calcImg} alt="calculator" /> </span><NavLink to="/calculations">Udregninger</NavLink></li>
              <li><span className='icon'><img src={globeImg} alt="globe" /> </span><NavLink to="/near">Vindmøller nær dig</NavLink></li>
              <li><span className='icon'><img src={xImg} alt="Close icon" /> </span><NavLink to="/dead">Afmeldte vindmøller</NavLink></li>
              <hr />
              {!inputs.faves.length == 0 ? <li id="gemteMøllerLi" className="header"></li> : null}
                
              {inputs?.faves?.map(function(item, index){
                return <li key={ item }><span>{windmill[item]?.Ejerlav === null ? "Ukendt" : windmill[item]?.Ejerlav}</span><Link to={`/${item}`}><span className='icon'><img src={globeImg} alt="globe" /> </span>{windmill[item]?.Kommune}</Link> <input type="checkbox" name="fave" checked={true} onChange={faveri} id={item} /></li>;
              
            })}
            <hr />
              {/* <form>
                <input type="text" placeholder="Find en mølle" id="message" name="message" onChange={handleChange}/>
              </form>
              <li></li> */}
              {/* {windmill?.map(function(item, index){
              if (inputs.message.length > 0) {
                count++
                
                              return <li className="windmillItems" key={ index }><span>{item.Ejerlav === null ? "Ukendt" : item.Ejerlav}</span><NavLink to={`/${index}`}>{item.Kommune}</NavLink> <input type="checkbox" name="fave" checked={ !inputs?.faves?.includes(index) ? false : true} onChange={faveri} id={index} /></li>;
                            }
                  })} */}
                  
            </ul>
            
        </nav>
        

        </SidebarStyle>
  )
}

export default MenuTo