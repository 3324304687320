import styled from "styled-components";

export const TheWindmillStyle = styled.div`
display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: max-content;
    width: 100%;
    #WindmillDiv{
     img{
        height: 50vh;
        margin-left: 2vw;
    }  
    .container{
      position: absolute;
      padding-top: 5vh;
      //margin-right: 25vw;
      margin-left: -2vw;
      
      height: 0;

      .stolby{
      position: relative;
      right:200px;
      left: auto;
      top: auto;
      margin-top: 10vh;
      
      svg{
        height: 250px; 
        
      }
     
    }
    .rotate {
    animation: rotation ${props => props.rpm}s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
    #v1{
      position: relative;
      height: 250px;
      width: 250px;
      bottom: 370px;
      left: auto;
      right: auto;
      top: auto;
      
      svg{
        height: 250px;
      width: 250px;
      }
      
    }
    } 
    
    }
    
    ul{
        
        margin-left: 0;
        padding-left: 0;
        overflow: visible;
        a{
            text-decoration: none;
            
        }
        li{
            font-size: 15px;
            margin-bottom: 2vh;
        }
    }
    #spacerDiv{
          display: none;
        }

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: max-content;
    width: 100%;
   
    #WindmillDiv{
     img{
        height: 50vh;
        margin-left: 2vw;
    }  
    .container{
      display: grid;
      position: absolute;
      padding-top: 5vh;
      
      width: 100%;
      height: 0;

      .stolby{
      position: relative;
      right:200px;
      left: auto;
      top: auto;
      margin-top: 10vh;
      
      svg{
        height: 250px; 
        
      }
     
    }
    .rotate {
    animation: rotation ${props => props.rpm}s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
    #v1{
      position: relative;
      height: 250px;
      width: 250px;
      bottom: 370px;
      left: auto;
      right: auto;
      top: auto;
      
      svg{
        height: 250px;
      width: 250px;
      }
      
    }
    } 
    
    }
    
    ul{
        width: 100vw;
        text-align: center;
        margin-top: 50vh;
        margin-left: 0;
        padding-left: 0;
        overflow: visible;
        a{
            text-decoration: none;
            
        }
        li{
            font-size: 15px;
            margin-bottom: 2vh;
        }
    }
  }
` 