import React from 'react'
import { TheWindmillStyle } from './TheWindmall.style';

function calculateRPM(windSpeed, bladeLength) {
  const circumference = 2 * Math.PI * bladeLength; // calculates the circumference of the blade rotation
  const distancePerMinute = windSpeed * 60; // calculates the distance the blade travels in a minute based on wind speed
  const rpm = distancePerMinute / circumference; // calculates the number of rotations per minute
  return rpm;
}

function sweptArea(radius) {
  const bladeArea = Math.PI * radius ** 2; // Calculate the area of one blade
  const totalArea = bladeArea * 1; // Multiply by the number of blades
  return totalArea;
}

function TheWindmill(probs) {
  let totalHeight = parseFloat(probs.windData.Navhøjde) + parseFloat(probs.rotorRadius)
  return (
    <>
    
      <TheWindmillStyle rpm={calculateRPM(probs.windspeed[probs.windspeed.length - 1] > 4 ? probs.windspeed[probs.windspeed.length - 1] : 0, probs.rotorRadius)}>
        <div id='WindmillDiv'>
          
          {/* <img src={require("../../Images/windmill.png")} alt="" /> */}
          <div className="container">
<div className="stolby">
<svg
      xmlns="https://www.w3.org/2000/svg"
      width="511.411"
      height="407.229"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 511.411 407.229"
      xmlSpace="preserve"
    >
      {/* MAIN WINDMILL START */}
      <path d="M374.238 407.229L336.238 407.229 346.238 32.229 364.238 32.229z"></path>
      <linearGradient
        id="SVGID_1_"
        x1="374.318"
        x2="332.984"
        y1="233.079"
        y2="234.413"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#6A4301"></stop>
        <stop offset="0.166" stopColor="#030500"></stop>
        <stop offset="0.497" stopColor="#06224B"></stop>
        <stop offset="1" stopColor="#132457"></stop>
      </linearGradient>
      {/* SLUT */}
      <path
        fill="url(#SVGID_1_)"
        d="M354.571 60.562c-3.076 0-6.105-.24-9.07-.698l-9.263 347.365h38l-9.27-347.601a59.445 59.445 0 01-10.397.934z"
      ></path>
      <path
        fill="#010E2B"
        d="M365.125.643s-45.873 12.13-40.393 26.906c5.48 14.776 50.432 2.771 50.432 2.771s14.19-8.514 7.741-21.09c-6.667-13.001-17.78-8.587-17.78-8.587z"
      ></path>
      <path d="M382.905 9.229a25.895 25.895 0 00-.591-1.082c.701 8.776-8.046 15.199-8.046 15.199s-41.478 15.309-49.883 2.916c.074.427.187.855.347 1.287 5.48 14.776 50.432 2.771 50.432 2.771s14.19-8.514 7.741-21.091z"></path>
      <path
        fill="#010E2B"
        d="M374.268 23.346s8.747-6.422 8.046-15.199C375.603-3.516 365.125.643 365.125.643s-43.229 11.432-40.739 25.619c8.405 12.393 49.882-2.916 49.882-2.916z"
      ></path>
      <circle cx="369.561" cy="15.612" r="15.611"></circle>
      <path
        fill="#8C330C"
        d="M351.489 8.312s-20.962 7.299-23.834 14.25c-2.146 5.193 22.5-2.667 22.5-2.667s-1.083-2.333-.666-6 2-5.583 2-5.583"
      ></path>
    </svg>
</div>
<div id="v1" className="rotate">
<svg
      xmlns="https://www.w3.org/2000/svg"
      width="386.318"
      height="383.263"
      x="0"
      y="0"
      viewBox="0 0 386.318 383.263"
    >
      <path d="M130.94 3.861s22.969 107.389 27.945 126.642c7.857 30.4 27.35 60.646 27.35 60.646l12.485-2.974S139.264-8.624 130.94.294M48.472 334.449s85.37-69.078 100.287-82.228c23.553-20.765 41.703-51.834 41.703-51.834l-8.302-9.789S33.571 332.677 45.291 336.063"></path>
      <path d="M383.356 248.43s-101.435-42.082-120.107-48.92c-29.485-10.797-65.449-11.929-65.449-11.929l-4.644 11.965s195.751 62.826 193.136 50.909"></path>
      <circle cx="193.021" cy="192.458" r="14.449"></circle>
    </svg>
  </div>

</div>

        </div>
      
      <ul>
      <li><strong> Møllenummer:</strong> {probs.windData.Møllenummer}</li>
      <li><strong> Ejerlav:</strong> {probs.windData.Ejerlav ? probs.windData.Ejerlav : "Ukendt"}</li>
      <li><strong> Nettilslutning:</strong> {probs.windData.Nettilslutning}</li>
      <li><strong>Fabrikat:</strong> {probs.windData.Fabrikat}</li>
      <li><strong>Model:</strong> {probs.windData.Model}</li>
      <li><strong>Antal {probs.windData.Model.toLowerCase()} i drift: </strong>{probs.modelObj?.Antal_i_DK ? probs.modelObj.Antal_i_DK : 0} </li>
      <li><strong>Rotor diameter:</strong> {probs.windData.Rotor_diameter} m</li>
      <li><strong>Navhøjde:</strong> {probs.windData.Navhøjde} m</li>
      <li><strong>Rotorareal:</strong> {sweptArea(probs.windData.Rotor_diameter / 2).toLocaleString('etc')} m2</li>
      <li><strong>Turbine total højde:</strong> {totalHeight.toLocaleString('etc')} m</li>
      <li><strong>Kommune:</strong> {probs.windData.Kommune}</li>
      <li><strong>Type af placering:</strong> {probs.windData.Placering}</li>
      
      {/* <a href={`https://en.wind-turbine-models.com/search?q=${probs.windData.Model.replace(" ", "_")}`}><h4>Se modellen her</h4></a> */}
      
      </ul>
      </TheWindmillStyle>
    
    </>
  )
}

export default TheWindmill