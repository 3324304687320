import styled from "styled-components";

export const GraphStyle = styled.div`

    height: 70vh;
    width: 90%;
    canvas{
        font-size: 50px;
        height: 10vh;
        
    }
    


@media only screen and (max-width: 768px) {
    /* For mobile phones: */
 
        height: 70vh;
        width: 95vw;
        canvas{
            font-size: 50px;
            height: 20vh;
            width: 100%;
        }
        
}
` 