import { useState } from 'react'
import Map from './Map'
import MobileMap from './MobileMap'
import { NearMeStyle } from './NearMe.style'

function NearMe() {
  const [isLoaded, setIsLoaded] = useState(false)
  
function isLoad() {
  setIsLoaded(true)
  ////console.log("loco");
}
setTimeout(() => {
  isLoad()
}, 400);

const nav = document.querySelectorAll('article')[0];
  
const mql = window.matchMedia('(max-width: 768px)');

let mobileView = mql.matches;
  return(
<NearMeStyle> 
  {mobileView ? isLoaded == true ? <MobileMap /> : <div>Loading...</div> : isLoaded == true ? <Map /> : <div>Loading...</div>}
      {}
    
  
  </NearMeStyle>
 
  ) 
}


export default NearMe