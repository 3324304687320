
import AppRouter from './AppRouter/AppRouter';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Sidebar from './Components/Sidebar/Sidebar';
import {GlobalStyle} from "./Global.style"
console.log("11-11-2023");

function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <section className='MainGrid'>
        <Sidebar />
      <AppRouter />
      </section>
      <Footer />
      
      
    </div>
  );
}

export default App;
