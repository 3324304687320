import styled from "styled-components";

export const NearMeStyle = styled.article`

border-top-left-radius: 10px;
border-bottom-left-radius: 10px;
padding: 2px;
background-color: white;
form{
  display: block;
  justify-content: space-between;
 input {
background-color: white;
  width: 100%;
  height: 2rem;
  font-size: 20px;

} 
}

div{

  border-radius: 0;
  
}

@media only screen and (max-width: 768px) {
  border-top-left-radius: 10px;
border-bottom-left-radius: 10px;
padding: 2px;
background-color: white;

form{
  display: block;
  justify-content: space-between;
 input {
background-color: white;
  width: 100%;
  height: 2rem;
  font-size: 20px;

} 
}

div{

  border-radius: 0;
  
}
}
`