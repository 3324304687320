import { WindyStyle } from './Windy.style';
function Windy(probs) {
  return (

  <WindyStyle>
  <iframe width="650" height="450"
        src={`https://embed.windy.com/embed2.html?lat=${probs.lat}&lon=${probs.long}&detailLat=${probs.lat}&detailLon=${probs.long}&zoom=11&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=true&calendar=now&pressure=true&type=map&location=coordinates&detail=&metricWind=m%2Fs&metricTemp=%C2%B0C&radarRange=-1`}
        frameBorder="0" title='Windy'></iframe>
  </WindyStyle>
  )
}

export default Windy