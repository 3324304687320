import styled from "styled-components";

export const WindyStyle = styled.div`
padding-left: 2vw;
    pointer-events: none;
    
      iframe{
        border-radius: 10px;
        margin-top: 2vh;
        height: 50vh;
        width: 90%;
        }  
    
  
    @media only screen and (max-width: 768px) {
      padding-left: 2vw;
        pointer-events: none;
        width: 95vw;
          iframe{
            border-radius: 10px;
            margin-top: 2vh;
            height: 50vh;
            width: 100%;
            }  
        
    } 
` 