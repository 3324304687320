import React from 'react'
import { DisclaimerStyle } from './Disclaimer.style'

export default function Disclaimer() {
  return (
    <DisclaimerStyle>
    <section id='disclaimer'>
      <h1>Disclaimer</h1>
      <p>De strømproduktionsestimater, der leveres på siden, er kun til informationsformål og bør ikke betragtes som garantier eller nøjagtige repræsentationer af den faktiske produktion. Beregningerne er baseret på en række faktorer, herunder gennemsnitlige vindhastigheder og andre relevante data fra Energistyrelsen. Det er dog vigtigt at bemærke, at virkelige forhold kan variere betydeligt, såsom lokale vejrforhold, stedsspecifikke karakteristika og vedligeholdelsespraksis, hvilket kan påvirke den faktiske strømproduktion. Derfor skal de oplysninger, der leveres, bruges som en generel retningslinje og ikke som en endelig målestok for vindmøllens ydeevne. Jeg, som ejer og admin af denne side, kan ikke holdes ansvarlig for eventuelle skader eller tab som følge af at stole på de præsenterede oplysninger. Jeg anbefaler brugerne at gå til kvalificerede fagfolk eller foretage yderligere undersøgelser, inden du træffer beslutninger baseret på de estimerede strømproduktions tal, der leveres her på siden.</p>
      <p>Jeg er en amatør vindmølle eututiast, med en tørst for ny viden og data. Det er også en af grundene til at jeg udviklede siden her. Da siden er et fritids hjerte projekt, så regner jeg heller ikke med at den nogensinde bliver gemt væk bag en betalingmur.  Denne side udvikles løbende, så fejl og mangler bliver også rettet som tiden går.</p>
      
      <p>Siden er til tænkt alle der ligesom jeg selv er nysgerrig og bare lidt interesseret i vindmøller.</p>
      
      <p>Alle vindmølle data fåes fra Energistyrelsen, men de data er dsv ikke altid komplette. Så hvis en vindmølle ikke har korrekte mål eller en korrekt placering, så er det derfor.</p>
      
      <p>Er en vindmølle ikke på siden, så er den simple forklaring at den endten er for lille, ikke er tilsluttet elnettet eller ikke er i det datasæt jeg har.</p>
      
      <p>Alle udregninger på siden er et ca tal udfra den data jeg har til rådighed. Hvilke udregner jeg har brugt, kan findes under "Udregninger".</p>
      

      <p>Kilder kan findes nederst på siden</p>
      </section>
      <div id='source'>
      <h4>Kilder:</h4>
      <ul>
      <li><a href="https://chat.openai.com/">ChatGBT</a></li>
        <li><a href="https://x-engineer.org/wind-turbine-energy/">Udregning af effekt</a></li>
        <li><a href="https://mmpa.org/wp-content/uploads/2015/09/Swept-Area-Provided-by-Kid-Wind-PDF.pdf">Rotorareral</a></li>
         <li><a href="https://ens.dk/service/statistik-data-noegletal-og-kort/data-oversigt-over-energisektoren">Vindmølledata</a></li>
         <li><a href="https://hvad-koster-det.dk/hvad-koster-en-vindmoelle/">Vindmølleomkostninger</a></li>
         <li><a href="https://ens.dk/sites/ens.dk/files/EnergiKlimapolitik/omkostninger_til_udbygning_med_vindkraft.pdf">Vindmølleomkostninger 2</a></li>
         
         
      </ul>
      </div>
      </DisclaimerStyle>
     
  )
}
