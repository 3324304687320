import styled from "styled-components";

export const SidebarStyle = styled.section`
 background-color: #1A74CA;
    .header{
        color: black;
        font-size: 20px;
    }
    #NavSelect{
    display: none;
}
    
    nav{
     
  hr {
    border-color: white;
    border-width: 1px;
    margin: 0.5rem;
    padding-left: 0;
    margin-left: 0;
  }
margin-left: 0rem;
padding-left: 0;
height: 93%;
ul{
    list-style: none;
   margin-left: 1rem;
padding-left: 0rem;
    li{
        margin-top: 0.5rem;

            .icon{
                display: inline;
                margin-left: 0;
            }
            span{
                display: block;
                margin-left: 2rem;
                img{
            height: 1.5rem;
            margin-right: 0.3rem;
        }
            }
            a{
                text-decoration: none;
                color: white;
                font-size: 1.5rem;
            }
        }
}
        
    }
    @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    
    .menuUl {
    padding-left: 0;
    margin-left: 0;
    }

    }
` 