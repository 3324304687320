import styled from "styled-components";


export const HistoricalStyle = styled.article`
display: grid;
grid-template-columns: 25% 75%;
width: 100%;
padding-left: 2vw;
border-top-left-radius: 10px;
background-color: white;
border-bottom-left-radius: 10px;
height: 100%;
#sidebarDiv{
    width: 100%;
    margin-right: 2vw;
    padding: 0.5rem;
    text-align: center;
    overflow: hidden;
    ul{
        text-align: left;
        list-style: none;
        padding: 0;
        line-height: 1.5rem;
    li{
       
    }
   }
}
#pieDiv{
    overflow: visible;
    width: auto;
    height: 50vh;
    
}
.cardOne,.cardTwo,.cardThree,.cardFour{

display: inline-block; 

background-color: #1A74CA;
padding: 1rem;
height: 10rem;
border-radius: 10px;
margin-top: 1rem;


}
#cardArea{
    color: white;
    margin-left: 2rem;    
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.cardOne{

    margin-right: 1rem;
}
.cardTwo{

    margin-right: 1rem;
}
.cardThree{
    margin-right: 1rem;
}
   .bottom{
    margin-top: 10vh;
   } 
   #lineDiv{
    width: 58vw;
    height: 50vh;
    
   }
   @media only screen and (max-width: 768px) {
  /* For mobile phones: */
  display: grid;
grid-template-columns: 100%;
width: 100%;
border-top-left-radius: 10px;
background-color: white;
border-bottom-left-radius: 10px;
height: 100%;
margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
#sidebarDiv{
    width: 100%;
    text-align: center;
    overflow: hidden;
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    ul{
        text-align: center;
        list-style: none;
        padding: 0;
        line-height: 1.5rem;
    li{
       
    }
   }
}
#pieDiv{
    overflow: visible;
    width: auto;
    height: 50vh;
    margin: 0;
    
}
.cardOne,.cardTwo,.cardThree,.cardFour{

display: inline-block; 

background-color: #1A74CA;
height: 10rem;
border-radius: 10px;
margin-top: 1rem;


}
#cardArea{  
    color: white; 
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0;
    margin-left: 1rem;
    padding: 0;
}

.cardOne{

    margin-right: 1rem;
}
.cardTwo{

    margin-right: 1rem;
}
.cardThree{
    margin-right: 1rem;
}
.cardFour{
    margin-right: 1rem;
}
   .bottom{
    margin-top: 10vh;
    margin-left: 1rem;
    text-align: left;
   } 
   #lineDiv{
    width: 90vw;
    height: 30vh;
    margin-left: 1rem;
    
   }

   }
`