import { LocationStyle } from './Location.style';
function Location(probs) {

  return (
   
      <LocationStyle>
        <h4>Placering</h4>
        <h6>{probs.ejerlav}</h6>
        
            <div className="gmap_canvas">
            <iframe title='googlemap' src={`https://maps.google.com/maps?q=${probs.lat},${probs.long}&t=k&z=13&ie=UTF8&iwloc=&maptype=satellite&output=embed`}  width="600" 
  height="370" 
></iframe>
        </div>
        </LocationStyle>
    
  )
}


export default Location