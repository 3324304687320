import React from 'react'
import { useParams } from 'react-router-dom';
import windmill from '../../windmill.json'

function Circle(probs) {
  // // const { id } = useParams()
  // // let better = (windmill[id][2022]/365)/24
  // // //console.log(better < probs.num ? probs.num - better.toFixed(1) + " mere siden samme tid sidste år" : probs.num - better.toFixed(1) + " mindre siden sidste år");
 
  return (
    <>
<div id='circle'>
    
    <h2>{probs.num} <span>{probs.span}</span><p>{parseInt(probs.num * probs.priceInDKK)} DKK</p></h2>
    
</div>
    </>
  )
}

export default Circle