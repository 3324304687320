import styled from "styled-components";

export const ProductionStyle = styled.article`
display: grid;

 h2{
        text-align: center;
    }

#circle{
    
    margin-left: 10vw;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    display: grid;
    border-color: #1A74CA;
    background-color: white;
    border-width: 20px;
    
    border-style: solid;
    h2{
        margin-top: 10vh;
        color: black;

    }
    p{
        margin: 0;
        padding: 0;
        display: block;
        font-size: 15px;
        text-align:center;
    }
}
@media only screen and (max-width: 768px) {
display: grid;
justify-content: center;
width: 100%;
 h2{
        text-align: center;
    }

#circle{
    
    margin-left: 10vw;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    display: grid;
    border-color: #1A74CA;
    background-color: white;
    border-width: 20px;
    
    border-style: solid;
    h2{
        margin-top: 10vh;
        color: black;

    }
    p{
        margin: 0;
        padding: 0;
        display: block;
        font-size: 15px;
        text-align:center;
    }
}
}
` 