import styled from "styled-components";

export const FooterStyle = styled.footer`
 display: grid;
    grid-template-columns: 20% repeat(3, 1fr);
    text-align: center;
    background-color: #1A74CA;  
    height: 1vh;
    margin-bottom: 0;
    padding-bottom: 0;
    color: white;
    #elpriser{


        height: 1vh;
        }
    #loveDiv{

        p{
                 margin-bottom: 0;
    padding-bottom: 0;  
    color: white;
        }
        height: 1vh;
        
        
    }
    #copyrightDiv{
        
        p{
                 margin-bottom: 0;
    padding-bottom: 0;   
    color: white;
        }
        height: 1vh;
    }
#contactInfo{

    display: flex;
    justify-content: center;
    font-size: 20px;
    height: 1vh;
    
    
    
    p{
        padding-left: 2vw;
        padding-right: 2vw;
        height: 1vh;
        margin-bottom: 0;
    padding-bottom: 0;
        a{
            text-decoration: none;
            color: black;
            
            
        }
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    display: grid;
    grid-template-columns:repeat(1, 1fr);
    text-align: center;
    background-color: #1A74CA;  
    height: 20vh;
    margin-bottom: 0;
    padding-bottom: 0;
    color: white;
    #elpriser{


        
        }
    #loveDiv{

        p{
                 margin-bottom: 0;
    padding-bottom: 0;  
    color: white;
        }
        
        
        
    }
    #copyrightDiv{
        
        p{
                 margin-bottom: 0;
    padding-bottom: 0;   
    color: white;
        }
        
    }
#contactInfo{

    display: flex;
    justify-content: center;
    font-size: 40px;
    margin-bottom: 5vh;
    
    
    
    
    p{
        padding-left: 2vw;
        padding-right: 2vw;
       
        margin-bottom: 0;
    padding-bottom: 0;
        a{
            text-decoration: none;
            color: black;
            
            
        }
    }
}
}
` 