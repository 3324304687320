import React from 'react'
import CompareData from '../../energyConsumption.json'
import { CompareStyle } from './Compare.style'

function Compare2(probs) {
  let energy1 = parseFloat(probs.power)/parseFloat(probs.onekWh_i_timen)
  let energy2 = parseFloat(probs.power)/parseFloat(4500)
  let energy3 = parseFloat(probs.power)/parseFloat(probs.threekWh_i_timen)
  let energy4 = parseFloat(probs.power)/parseFloat(probs.fourkWh_i_timen)
  return (
    <CompareStyle>
      <h2>...</h2>
      <div className='energyContainer'>
        <div className='energyCard'>at du kan {probs.oneHandling} i {energy1.toLocaleString('etc')} timer</div>
      <div className='energyCard'>at du kan holde gang i {energy2.toFixed(1)} {energy2.toFixed(1) > 1 ? "danske husholdninger" : "dansk husholdning"} i et helt år</div>
      <div className='energyCard'>at du kan {probs.threeHandling} i {energy3.toLocaleString('etc')} timer</div>
      <div className='energyCard'>at du kan {probs.fourHandling} i {energy4.toLocaleString('etc')} timer</div> 
      </div>

    </CompareStyle>
    
  )
}

export default Compare2