import { WeatherStyle } from './Weather.style';
function Weather(probs) {
if (!probs.weatherlength == 0) {
        return (
    
            <WeatherStyle>
        <h4>Vejret lige nu</h4>
        <h6>80 m oppe</h6>
        <div id='weatherDiv'>
            <div>
                <p>Vindhastighed</p>
                <img src={require("../../Images/windspeed.png")} alt="Windspeed icon" />
                <h2>{probs.windspeed} m/s</h2>
            </div>
    
            <div>
                <p>Vindretning</p>
                <img src={require("../../Images/direction.png")} alt="Wind direction icon" />
                <h2>{probs.deg}</h2>
            </div>
    
            <div>
                <p>Luftfugtighed</p>
                <img src={require("../../Images/humidity.png")} alt="Humidity icon" />
                <h2>{probs.humidity}%</h2>
            </div>
    
            <div id="forcastDiv">
                <p>Vejrudsigt</p>
                <img id='forcastImg' src={probs.forcastImg} alt="forecast icon" />
                <h2>{probs.forcastDesc}</h2>
            </div>
    
            <div>
                <p>Lufttryk</p>
                <img src={require("../../Images/pressure.png")} alt="pressure icon" />
                <h2>{probs.pressure} hPa</h2>
            </div>
    
            <div>
                <p>Temperatur</p>
                <img src={require("../../Images/temp.png")} alt="temperature icon" />
                <h2>{probs.temp}°C</h2>
            </div>
        </div>
        </WeatherStyle>
      )
      }else{
        return <div>Loading...</div>
      }
}

export default Weather