import { DivStyle } from "./calc.style.js"
import Calcc from "./Calcc.jsx"

function Calculations() {
  
  return (
    <>
    
      <DivStyle>
      <div id='calc'>
      <h1>Udregninger</h1>
      <h2>Rotor areal</h2>
      <p>Rotorarealet (Sweapt area) af en vindmølle refererer til det samlede område, som vingerne svejer igennem, når de roterer omkring vindmøllens centrale akse. For at beregne dette areal skal vi kende radius af vingerne og antallet af vinger.</p>
      <p>Formlen for arealet af en cirkel er πr^2, hvor π er den matematiske konstant pi (ca. 3,14159) og r er radius af cirklen. Vi kan bruge denne formel til at beregne arealet af en vinge på vindmøllen.</p>
      <code>bladeArea = Math.PI * radius ** 2</code>
      <p>For at finde det samlede rotorareal af vindmøllen skal vi gange arealet af én vinge med antallet af vinger:</p>
      <code>totalArea = bladeArea * numBlades</code>
      <p>Så sweptArea()-funktionen beregner først arealet af én vinge ved at bruge formlen for arealet af en cirkel, og derefter ganger den med antallet af vinger for at få det samlede udswept område. Funktionen returnerer derefter denne værdi.</p>
      <p>Bemærk, at denne beregning forudsætter, at vingerne er ens i størrelse og form, og at de er jævnt fordelt omkring den centrale akse på vindmøllen. I virkeligheden kan vindmølledesign variere meget, så denne formel kan måske ikke være nøjagtig for alle vindmøller.</p>
      <h2>Koden:</h2>
      <h4>Funktionen</h4>
      <code>
        <p>{"function sweptArea(radius, numBlades) {"}</p>
        <p>{"const bladeArea = Math.PI * radius ** 2; // Calculate the area of one blade"}</p>
        <p>{"const totalArea = bladeArea * numBlades; // Multiply by the number of blades"}</p>
        <p>{"return totalArea;"}</p>
        <p>{"}"}</p>
              </code>

              <h4>Variablerne</h4>
              <code>
        <p>{"const radius = 10; // radius of the blades in meters"}</p>
        <p>{"const numBlades = 1; // number of blades"}</p>
        <p>{"const area = sweptArea(radius, numBlades); // calculate the swept area"}</p>
              </code>

              <h2>Production</h2>
              <h2>Koden:</h2>
              <h4>Funktionen</h4>
              <h4>Variablerne</h4>

              <h2>omdrejninger pr. minut</h2>
              <p>For at beregne RPM (omdrejninger pr. minut) på en vindmølle skal du kende vindhastigheden og vinge længden.</p>
              <h2>Koden:</h2>
              
              <h4>Funktionen</h4>
              <code>
        <p>{"function calculateRPM(windSpeed, bladeLength) {"}</p>
        <p>{"const circumference = 2 * Math.PI * bladeLength; // beregner omkredsen af vinge rotationen"}</p>
        <p>{"const distancePerMinute = windSpeed * 60; // beregner afstanden bladet rejser i et minut baseret på vindhastigheden"}</p>
        <p>{"const rpm = distancePerMinute / circumference; // beregner antallet af omdrejninger pr. minut"}</p>
        <p>{"return rpm;"}</p>
        <p>{"}"}</p>
              </code>
              
              <h4>Variablerne</h4>
              <code>
                <p>{"const rpm = calculateRPM(5, 2.5);"}</p>
              </code>
      </div>
      
      <div id="spacerDiv"></div>
    </DivStyle>
    </>
  )
}

export default Calculations