import styled from "styled-components";

export const DivStyle = styled.div `
padding-left: 2vw;
border-top-left-radius: 10px;
background-color: white;
border-bottom-left-radius: 10px;
height: 100%;
#spacerDiv{
    height: 4vh;
}
`