import styled from "styled-components";

export const WeatherStyle = styled.article`
width: 100%;
#weatherDiv{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        text-align: center;
        img{
            height: 5vh;
        }
        #forcastDiv{
                    #forcastImg{
            height: 8vh;
        }
        h2{
            margin-top: 0;
        }
        }

        h2{}
    }
    @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    width: 100%;
#weatherDiv{
    width: 95vw;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        text-align: center;
        img{
            height: 5vh;
        }
        #forcastDiv{
                    #forcastImg{
            height: 8vh;
        }
        h2{
            margin-top: 0;
        }
        }

        h2{}
    }
  }
` 