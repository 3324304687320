import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import windmill from "../../windmill.json"
import { useParams } from 'react-router-dom';
import { SidebarStyle } from './Sidebar.style';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import Header from '../Header/Header';
import Menu from '../hamburgermenu/menu';
import MenuTo from '../hamburgermenu/MenuTo';

function Sidebar() {
  const nav = document.getElementById('navMenu');
  
  const mql = window.matchMedia('(max-width: 768px)');

  let mobileView = mql.matches;
  

  return (
    
      <SidebarStyle>
        <Header />
        {!mobileView ? <MenuTo /> : <Menu pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />}

        

        </SidebarStyle>
  )
}

export default Sidebar