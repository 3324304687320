import { useState } from 'react'
import Map from './Map'
import { DeadStyle } from './Dead.style'

function Dead() {
  const [isLoaded, setIsLoaded] = useState(false)
  
function isLoad() {
  setIsLoaded(true)
  ////console.log("loco");
}
setTimeout(() => {
  isLoad()
}, 400);
  return(
<DeadStyle> 
  
      {isLoaded == true ? <Map /> : <div>Loading...</div>}
    
  
  </DeadStyle>
 
  ) 
}


export default Dead