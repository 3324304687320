import { useEffect } from 'react';
import Circle from './Circle';
import { ProductionStyle } from './Production.style';
function Production(probs) {
  let power = 0
  let price = 0
  if (!probs.weather) {
    
    return <div>loading...</div>
  }else{
   // 
   let hours = probs.update == false ? new Date().getHours() + 1 : 24
    //console.log(probs.price);
    for (let index = 0; index < hours; index++) {
      const element = probs.thepower[index];
      const element2 = probs.price[index];
      power = Number(power) + Number(element)
      price = Number(price) + Number(element2)


    }
    return (
    <ProductionStyle>
    <h4>{probs.update == false ? "Dagens produktion indtil videre" : "Hele dagens produktion"} <h6>{Date()}</h6></h4>
    
    <Circle 
    num={power ? power : power !== 0 ? "no info" : 0} span={power ? "kWh" : power === 0 ? "kWh" : ""}
    priceInDKK={price / hours}
    update={probs.update}
    />
   </ProductionStyle>


  )
  }
  
}

export default Production