import styled from "styled-components";

export const CompareStyle = styled.div`

.energyContainer{
    display: grid;
    grid-template-columns: repeat(2, 1fr);   
    .energyCard{
        color: white;
    background-color: black;
    border-radius: 10px;
    padding: 0.5rem;
    margin: 0.5rem;
    }
}
margin-bottom: 1rem;
`