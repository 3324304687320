import styled from "styled-components";

export const HeaderStyle = styled.header`

    display: grid;
    text-align: center;
    border-top-left-radius: 10px;
    background-color: #1A74CA;
    div{
        background-color: #1A74CA;
        
    }

    #logo{
        height: 15vh;
        
        padding-top: 6vh;
    }


@media only screen and (max-width: 768px) {
    /* For mobile phones: */

        display: grid;
        grid-template-columns: 100%;
        text-align: center;
        border-top-left-radius: 10px;
        background-color: #1A74CA;
        width: 100%;
        div{
            background-color: #1A74CA;
            display: grid;
            justify-content: center;
            width: 100%;

        }
        article{
            display: none;
            background-color: rgb(255, 255, 255);
            border-top-left-radius: 10px;
        }
        #logo{
            height: 10vh;
            padding-top: 7vh;
        }
    }
  
` 