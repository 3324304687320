import React from 'react'
import Sidebar from '../../Components/Sidebar/Sidebar'

function NotFound(probs) {
  return (
    <>
    <h1>Ikke fundet???</h1>
    <div>{probs.date}</div>
    </>
    
  )
}

export default NotFound